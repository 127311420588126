<template>
    <div>
        <v-img class="mt-5 hidden-sm-and-down" style="border-radius:20px;"
        src="https://inmoglobalve.com.ve/img/fotos-min.jpg" height="400"
        >
        <p class="hidden-sm-and-down" style="
        text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
        font-size:35px;color:white;font-weight:700;text-align:center;padding-top:15px;">
            Propiedades en venta y alquiler
        </p>
        <v-card flat style="background:#ff150063;
        width:80%;border-radius:20px;margin:5vh auto;display:block;"
        >
        <v-card-text style="padding:50px;margin:0 auto;display;block;">

          <v-layout row justify-center>
              <v-flex xs12 md2 style="text-align:center;">
                  <span style="text-align:center;color:#fff;font-weight:700;">Tipo de Búsqueda</span>
                  <label>
                        <v-select outlined background-color="#fff" style="color:#000;width:90%;margin:0 auto;display:block;"
                    :items="TipoDeBusqueda" dense
                    item-color="#00174A"
                    color="#00174A"
                    
                    @change="changeTipo($event)"
                    ></v-select>
                    </label>
              </v-flex>
              <v-flex xs12 md2 style="text-align:center;">
                  <span style="text-align:center;color:#fff;font-weight:700;">Estado</span>
                  <label>
                        <v-select outlined background-color="#fff"
                        item-color="#00174A"
                        color="#00174A"
                    :items="Estados" dense style="width:90%;margin:0 auto;display:block;"
                    @change="onChangeEstado($event)"
                    ></v-select>
                    </label>
              </v-flex>
              <v-flex xs12 md2 style="text-align:center;">
                  <span style="text-align:center;color:#fff;font-weight:700;">Municipio</span>
                  <label>
                        <v-select outlined background-color="#fff"
                        item-color="#00174A"
                        color="#00174A"
                    :items="Municipios" dense style="width:90%;margin:0 auto;display:block;"
                    @change="changeCiudad($event)"
                    ></v-select>
                    </label>
              </v-flex>
              <v-flex xs12 md2 style="text-align:center;">
                  <span style="text-align:center;color:#fff;font-weight:700;">Inmueble</span>
                  <label>
                        <v-select outlined background-color="#fff"
                        item-color="#00174A"
                        color="#00174A"
                    :items="Inmuebles" dense style="width:90%;margin:0 auto;display:block;"
                    @change="changeInmuebles($event)"
                    ></v-select>
                    </label>
              </v-flex>
              <v-flex xs12 md2>
                  <v-btn text
                    
                    @click="filtratResult({
                        pr_tipo: pr_tipo,
                        pr_estado: pr_estado,
                        pr_municipio: pr_municipio,
                        pr_categoria: pr_categoria,
                    })" 
                    class="dfdfg"
                    style="
                    width:90%;margin:30px auto;display:block;
                    border-radius:20px;color:white;text-transform:none;
                    font-weight:700;
                    ">
                    Buscar
                    </v-btn>
              </v-flex>
          </v-layout>



          <v-layout row justify-center>
              <v-flex xs12 md2 style="text-align:center;">
              </v-flex>
              <v-flex xs12 md2 style="text-align:center;">
              </v-flex>
              <v-flex xs12 md2 style="text-align:center;">
              </v-flex>
              <v-flex xs12 md2 style="text-align:center;">
                    <label>
                    <span style="text-align:center;color:#fff;font-weight:700;">Buscar por Código</span><br>
                      <input v-model="pr_codigo"
                      type="text" style="width:90%;outline:0;border:solid 1px #ccc;background:white;
                      border-radius:10px;color:black;text-align:center;padding:10px 10px;
                      ">
                  </label>
              </v-flex>
              <v-flex xs12 md2>
                  <v-btn text
                    
                    @click="BuscarPorCodigoInmueble({
                        pr_codigo: pr_codigo
                    })" 
                    class="dfdfg"
                    style="
                    width:90%;margin:30px auto;display:block;
                    border-radius:20px;color:white;text-transform:none;
                    font-weight:700;
                    ">
                    Buscar
                    </v-btn>
              </v-flex>
          </v-layout>
        </v-card-text>
        </v-card>
        </v-img>

        <v-img class="hidden-md-and-up" style="border-radius:20px;"
        src="https://inmoglobalve.com.ve/img/fotos-min.jpg" height="700"
        >
        <p class="hidden-sm-and-down" style="
        text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
        font-size:35px;color:white;font-weight:700;text-align:center;padding-top:15px;">
            Propiedades en venta y alquiler
        </p>
        <v-card flat style="background:#ff150063;
        width:80%;border-radius:20px;margin:20px auto;display:block;"
        >
        <v-card-text style="padding:50px;margin:0 auto;display;block;">

          <v-layout row justify-center>
              <v-flex xs12 md2 style="text-align:center;">
                  <span style="text-align:center;color:#fff;font-weight:700;">Tipo de Búsqueda</span>
                  <label>
                        <v-select outlined background-color="#fff" style="color:#000;width:90%;margin:0 auto;display:block;"
                    :items="TipoDeBusqueda" dense
                    item-color="#00174A"
                    color="#00174A"
                    
                    @change="changeTipo($event)"
                    ></v-select>
                    </label>
              </v-flex>
              <v-flex xs12 md2 style="text-align:center;">
                  <span style="text-align:center;color:#fff;font-weight:700;">Estado</span>
                  <label>
                        <v-select outlined background-color="#fff"
                        item-color="#00174A"
                        color="#00174A"
                    :items="Estados" dense style="width:90%;margin:0 auto;display:block;"
                    @change="onChangeEstado($event)"
                    ></v-select>
                    </label>
              </v-flex>
              <v-flex xs12 md2 style="text-align:center;">
                  <span style="text-align:center;color:#fff;font-weight:700;">Municipio</span>
                  <label>
                        <v-select outlined background-color="#fff"
                        item-color="#00174A"
                        color="#00174A"
                    :items="Municipios" dense style="width:90%;margin:0 auto;display:block;"
                    @change="changeCiudad($event)"
                    ></v-select>
                    </label>
              </v-flex>
              <v-flex xs12 md2 style="text-align:center;">
                  <span style="text-align:center;color:#fff;font-weight:700;">Inmueble</span>
                  <label>
                        <v-select outlined background-color="#fff"
                        item-color="#00174A"
                        color="#00174A"
                    :items="Inmuebles" dense style="width:90%;margin:0 auto;display:block;"
                    @change="changeInmuebles($event)"
                    ></v-select>
                    </label>
              </v-flex>
              <v-flex xs12 md2>
                  <v-btn text
                    
                    @click="filtratResult({
                        pr_tipo: pr_tipo,
                        pr_estado: pr_estado,
                        pr_municipio: pr_municipio,
                        pr_categoria: pr_categoria,
                    })" 
                    class="dfdfg"
                    style="
                    width:90%;margin:0 auto;display:block;
                    border-radius:20px;color:white;text-transform:none;
                    font-weight:700;
                    ">
                    Buscar
                    </v-btn>
              </v-flex>
          </v-layout>


            <v-divider style="margin:25px 0px 25px 0px;background:white"></v-divider>


          <v-layout row justify-center>
              <v-flex xs12 md2 style="text-align:center;">
              </v-flex>
              <v-flex xs12 md2 style="text-align:center;">
              </v-flex>
              <v-flex xs12 md2 style="text-align:center;">
              </v-flex>
              <v-flex xs12 md2 style="text-align:center;">
                    <label>
                    <span style="text-align:center;color:#fff;font-weight:700;">Buscar por Código</span><br>
                      <input v-model="pr_codigo"
                      type="text" style="width:90%;outline:0;border:solid 1px #ccc;background:white;
                      border-radius:10px;color:black;text-align:center;padding:10px 10px;
                      ">
                  </label>
              </v-flex>
              <v-flex xs12 md2>
                  <v-btn text
                    
                    @click="BuscarPorCodigoInmueble({
                        pr_codigo: pr_codigo
                    })" 
                    class="dfdfg"
                    style="
                    width:90%;margin:30px auto;display:block;
                    border-radius:20px;color:white;text-transform:none;
                    font-weight:700;
                    ">
                    Buscar
                    </v-btn>
              </v-flex>
          </v-layout>
        </v-card-text>
        </v-card>
        </v-img>
        
    </div>
</template>

<style>
.dfdfg{

background: rgba(0,88,176,1);
background: -moz-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,88,176,1)), color-stop(100%, rgba(1,43,82,1)));
background: -webkit-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: -o-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: -ms-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: linear-gradient(to bottom, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0058b0', endColorstr='#012b52', GradientType=0 );
}
.dfdfg:hover {
    box-shadow: 0px 0px 9px 0px rgba(255,255,255,0.66);
}
.bgmotos{

background: #ff150063;

}
</style>

<script>
export default {
    data(){
        return {
            precioDelDolar:'',pr_codigo:'',
            pr_tipo:'',pr_categoria:'',pr_municipio:'',pr_estado:'',
            esstado:'',Municipios:[],
            Inmuebles:[
                'SELECCIONAR','FONDO DE COMERCIO', 'POSADAS',  'APARTAMENTOS', 'PENTHOUSE'
                , 'TOWNHOUSE'
                , 'CASA'
                , 'TERRENO'
                , 'FINCAS'
                , 'EDIFICIOS'
                , 'GALPON'
                , 'LOCAL'
                , 'OFICINA'
            ],
            TipoDeBusqueda:[
                'SELECCIONAR', 'ALQUILER', 'VENTA', 'VENTA / ALQUILER'
            ],
            Estados:[
                'SELECCIONAR',
                'AMAZONAS',
                'ANZOÁTEGUI',
                'APURE',
                'ARAGUA',
                'BARINAS',
                'BOLÍVAR',
                'CARABOBO',
                'COJEDES',
                'DELTA AMACURO',
                'DISTRITO CAPITAL',
                'FALCÓN',
                'GUÁRICO',
                'LARA',
                'MÉRIDA',
                'MIRANDA',
                'MONAGAS',
                'NUEVA ESPARTA',
                'PORTUGUESA',
                'SUCRE',
                'TÁCHIRA',
                'TRUJILLO',
                'VARGAS',
                'YARACUY',
                'ZULIA'
            ],
        }
    },
    methods:{
        filtratResult(){
            if(this.pr_tipo == '' || this.pr_estado == '' || this.pr_municipio == '' || this.pr_categoria == ''){
                alert('Seleccionar todos los campos y encuentra el inmueble de tus sueños.')
            }else {
                window.open(`/Busqueda?tipo=${this.pr_tipo}&estado=${this.pr_estado}&municipio=${this.pr_municipio}&categoria=${this.pr_categoria}`,"_self")
            }
        },
        BuscarPorCodigoInmueble(){
            if(this.pr_codigo == ''){
                alert('Introduzca un código para realizar la busqueda')
            }else {
                window.open(`/BusquedaRapida?pr_codigo=${this.pr_codigo}`,"_self")
            }
        },
        changeInmuebles(event){
            this.pr_categoria = event
        },
        changeTipo(event){
            this.pr_tipo = event
        },
        changeCiudad(event){
            this.pr_municipio = event
        },
        onChangeEstado(e){
            let ests = e
        if (ests == 'AMAZONAS') {
            this.Municipios = [
                'SELECCIONAR',
                'MAROA',
                'PUERTO AYACUCHO',
                'SAN FERNANDO DE ATABAPO'
            ]
            this.pr_estado = 'AMAZONAS'
            
        }
        if (ests == 'ANZOÁTEGUI') {
            this.Municipios = [
                'SELECCIONAR',
                'ANACO',
                'ARAGUA DE BARCELONA',
                'BARCELONA',
                'BOCA DE UCHIRE',
                'CANTAURA',
                'CLARINES',
                'EL CHAPARRO',
                'EL PAO ANZOATEGUI',
                'EL TIGRE',
                'EL TIGRITO',
                'GUANAPE',
                'GUANTA',
                'LECHERIA',
                'ONOTO',
                'PARIAGUAN',
                'PIRITU',
                'PUERTO LA CRUZ',
                'PUERTO PIRITU',
                'SABANA DE UCHIRE',
                'SAN MATEO ANZOATEGUI',
                'SAN PABLO ANZOATEGUI',
                'SANTA ANA DE ANZOATEGUI',
                'SANTA FE ANZOATEGUI',
                'SANTA ROSA',
                'SAN TOME',
                'SOLEDAD',
                'URICA',
                'VALLE DE GUANAPE'
            ]
            this.pr_estado = 'AMAZONAS'
            
        }
        if (ests == 'APURE') {
            this.Municipios = [
                'SELECCIONAR',
                'ACHAGUAS',
                'BIRUACA',
                'BRUZUAL',
                'AL AMPARO',
                'EL NULA',
                'ELORZA',
                'EL SAMAN DE APURE',
                'GUASDUALITO',
                'MANTECAL',
                'PUERTO PAEZ',
                'SAN FERNANDO DE APURE',
                'SAN JUAN DE PAYARA'
            ]
            this.pr_estado = 'APURE'
            
        }
        if (ests == 'ARAGUA') {
            this.Municipios = [
                'SELECCIONAR',
                'BOLIVAR',
                'CAMATAGUA',
                'FRANCISCO LINARES ALCANTARA',
                'GIRARDOT',
                'JOSE ANGEL LAMAS',
                'JOSE FELIX RIBAS',
                'JOSE RAFAEL REVENGA',
                'LIBERTADOR',
                'MARIO BRICEÑO IRAGORRY',
                'OCUMARE DE LA COSTA DE ORO',
                'SAN CASIMIRO',
                'SAN SEBASTIAN',
                'SANTIAGO MARIÑO',
                'SANTOS MICHELENA',
                'SUCRE',
                'TOVAR',
                'URDANETA',
                'ZAMORA',
            ]
            this.pr_estado = 'ARAGUA'
            
        }
        if (ests == 'BARINAS') {
            this.Municipios = [
                'SELECCIONAR',
                'ALTAMIRA',
                'ARISMENDI',
                'BARINAS',
                'BARINITAS',
                'CALDERAS',
                'CAPITANEJO',
                'CIUDAD BOLIVIA',
                'CIUDAD DE NUTRIAS',
                'DOLORES',
                'EL CANTON',
                'GUADARRAMA',
                'LAS VEGUITAS',
                'LA UNION',
                'LIBERTAD DE BARINAS',
                'OBISPOS',
                'QUEBRADA SECA',
                'SABANETA',
                'SAN SILVESTRE',
                'SANTA BARBARA DE BARINAS',
                'SANTA ROSA',
                'SOCOPO'                
            ]
            this.pr_estado = 'BARINAS'
            
        }
        if (ests == 'BOLÍVAR') {
            this.Municipios = [
                'SELECCIONAR',
                'CAICARA DEL ORINOCO',
                'CIUDAD BOLIVAR',
                'CIUDAD PIAR',
                'EL CALLAO',
                'EL DORADO',
                'EL MANTECO',
                'EL PALMAR',
                'EL PAO',
                'GUASIPATI',
                'LA PARAGUA',
                'MARIPA',
                'MATANZAS',
                'PUERTO ORDAZ',
                'SAN FELIX',
                'SANTA ELENA DE UAIREN',
                'SOLEDAD',
                'TUMEREMO',
                'UPATA'
                
            ]
            this.pr_estado = 'BOLÍVAR'
            
        }
        if (ests == 'CARABOBO') {
            this.Municipios = [
                'SELECCIONAR',
                'BEJUMA',
                'CARLOS ARVELO',
                'DIEGO IBARRA',
                'GUACARA',
                'JUAN JOSE MORA',
                'LIBERTADOR',
                'LOS GUAYOS',
                'MIRANDA',
                'MONTALBAN',
                'NAGUANAGUA',
                'PUERTO CABELLO',
                'SAN DIEGO',
                'SAN JOAQUIN',
                'VALENCIA',       
            ]
            this.pr_estado = 'CARABOBO'
            
        }
        if (ests == 'COJEDES') {
            this.Municipios = [
                'SELECCIONAR',
                'COJEDES',
                'EL PAO',
                'TINAQUILLO',
                'EL BAUL',
                'MACAPO',
                'LIBERTAD',
                'LAS VEGAS',
                'SAN CARLOS',
                'TINACO',
            ]
            this.pr_estado = 'COJEDES'
            
        }
        if (ests == 'DELTA AMACURO') {
            this.Municipios = [
                'SELECCIONAR',
                'TUCUPITA'                
            ]
            this.pr_estado = 'DELTA AMACURO'
            
        }
        if (ests == 'DISTRITO CAPITAL') {
            this.Municipios = [
                'SELECCIONAR',
                'CARACAS'                
            ]
            this.pr_estado = 'DISTRITO CAPITAL'
            
        }
        if (ests == 'FALCÓN') {
            this.Municipios = [
                'SELECCIONAR',
                'ACOSTA',
                'BOLIVAR',
                'BUCHIVACOA',
                'CARIRUBANA',
                'COLINA',
                'DABAJURO',
                'DEMOCRACIA',
                'FALCON',
                'FEDERACION',
                'ITURRIZA',
                'JACURA',
                'LOS TAQUES',
                'MANAURE',
                'MAUROA',
                'MIRANDA',
                'PALMASOLA',
                'PETIT',
                'PIRITU',
                'SAN FRANCISCO',
                'SUCRE',
                'SILVA',
                'TOCOPERO',
                'UNION',
                'URUMACO',
                'ZAMORA',
            ]
            this.pr_estado = 'FALCÓN'
            
        }
        if (ests == 'GUÁRICO') {
            this.Municipios = [
                'SELECCIONAR',
                'ALTAGRACIA DE ORITUCO',
                'BARBACOA',
                'CABRUTA',
                'CALABOZO',
                'CAMAGUAN',
                'CAMATAGUA',
                'CARMEN DE CURA',
                'CHAGUARAMAS GUARICO',
                'EL CALVARIO',
                'EL RASTRO',
                'EL SOCORRO',
                'EL RASTRO',
                'EL SOCORRO',
                'EL SOMBRERO',
                'GUARDATINAJA',
                'LAS MERCEDES DE LOS LLANOS',
                'LEZAMA',
                'ORTIZ',
                'SAN CASIMIRO',
                'SAN JOSE DE GUARIBE',
                'SAN JOSE UNARE',
                'SAN JUAN DE LOS MORROS',
                'SAN RAFAEL DE LAYA',
                'SAN SEBASTIAN',
                'SANTA MARIA DE IPIRE',
                'TUCUPIDO',
                'VALLE DE LA PASCUA',
                'ZARAZA'
                
            ]
            this.pr_estado = 'GUÁRICO'
            
        }
        if (ests == 'LARA') {
            this.Municipios = [
                'SELECCIONAR',
                'AGUADA GRANDE',
                'ALTAGRACIA',
                'AREGUE',
                'ATARIGUA',
                'BARAGUA',
                'BARQUISIMETO',
                'BOBARE',
                'BUENA VISTA',
                'CABUDARE',
                'CARIRAGUA',
                'CARORA',
                'CUBIRO',
                'DUACA',
                'EL EMPEDRADO',
                'EL TOCUYO',
                'GUARICO',
                'GUAYABONES',
                'HUMOCARO ALTO',
                'HUMOCARO BAJO',
                'LA ESCALERA',
                'LA MIEL',
                'MOYETONES',
                'PIE DE CUESTA',
                'QUIBOR',
                'RIO BLANCO',
                'RIO TOCUYO',
                'SANARE',
                'SAN FRANCISCO',
                'SAN PEDRO',
                'SANTA INES',
                'SARARE',
                'VILLA NUEVA'
                
            ]
            this.pr_estado = 'LARA'
            
        }
        if (ests == 'MÉRIDA') {
            this.Municipios = [
                'SELECCIONAR',
                'ACEQUIAS',
                'ARAPUEY',
                'BAILADORES',
                'CANAGUA',
                'CAPAZON',
                'CHACHOPO',
                'CHIGUARA',
                'CHOCANTA',
                'EJIDO',
                'EL VIGIA',
                'JAJI',
                'LA AZULITA',
                'LAGUNILLAS MERIDA',
                'LA PLAYA',
                'LAS VIRTUDES',
                'MERIDA',
                'MESA DE BOLIVAR',
                'MESA DE QUINTERO',
                'MUCUCHIES',
                'MUCUJEPE',
                'MUCURUBA',
                'MUCUTUY',
                'NUEVA BOLIVIA',
                'PALMARITO',
                'PIÑANGO',
                'PUEBLO LLANO',
                'PUEBLO NUEVO',
                'SAN CRISTONBAL DE TORONDOY',
                'SANTA APOLONIA DE MERIDA',
                'SANTA CRUZ DE MORA',
                'SANTA ELENA DE ARENALES',
                'SANTO DOMINGO',
                'TABAY',
                'TIMONES',
                'TORONDOY',
                'TOVAR',
                'TUCANI',
                'ZEA'
                
            ]
            this.pr_estado = 'MÉRIDA'
            
        }
        if (ests == 'MIRANDA') {
            this.Municipios = [
                'SELECCIONAR',
                'ARAGUITA',
                'CAPAYA',
                'CARRIZAL',
                'CAUCAGUA',
                'CHACAO',
                'CHARALLAVE',
                'CUA',
                'CUPIRA',
                'CURIEPE',
                'EL GUAPO',
                'EL JARILLO',
                'FILAS DE MARICHE',
                'GUARENAS',
                'GUATIRE',
                'HIGUEROTE',
                'LOS TEQUES',
                'MACHURUCUTO',
                'OCUMARE DEL TUY',
                'PANAQUIRE',
                'PARACOTOS',
                'RIO CHICO',
                'SAN ANTONIO DE LOS ALTOS',
                'SAN FRANCISCO DE YARE',
                'SAN JOSE DE BARLOVENTO',
                'SANTA LUCIA',
                'SANTA TERESA',
                'TACARIGUA DE LA LAGUNA',
                'TACARIGUA DE MAMPORAL',
                'TACATA'
            ]
            this.pr_estado = 'MIRANDA'
            
        }
        if (ests == 'MONAGAS') {
            this.Municipios = [
                'SELECCIONAR',
                'AGUASAY',
                'ARAGUA DE MATURIN',
                'BARRANCAS DEL ORINOCO',
                'CAICARA DE MATURIN',
                'CARIPE',
                'CARIPITO',
                'CHAGUARAMAL',
                'CHAGUARAMAS MONAGAS',
                'EL FURRIAL',
                'EL TEJERO',
                'GUANAGUANA',
                'JUSEPIN',
                'LA TOSCANA',
                'MATURIN',
                'MIRAFLORES',
                'PUNTA DE MATA',
                'QUIRIQUIRE',
                'SAN ANTONIO',
                'SAN ANTONIO DE TABASCA',
                'SAN FELIX DE MONAGAS',
                'SANTA BARBARA',
                'SAN VICENTE',
                'TEMBLADOR',
                'TERESEN',
                'URACOA',
                'VIENTO FRESCO'
                
            ]
            this.pr_estado = 'MONAGAS'
            
        }
        if (ests == 'NUEVA ESPARTA') {
            this.Municipios = [
                'SELECCIONAR',
                'ALTAGRACIA',
                'BOCA DE POZO',
                'BOCA DE RIO',
                'EL VALLE DEL ESPIRITU SANTO',
                'JUANGRIEGO',
                'LA ASUNCION',
                'LA GUARDIA',
                'PAMPATAR',
                'PORLAMAR',
                'PUNTA DE PIEDRAS',
                'SAN JUAN BAUTISTA',
                'SAN PEDRO DE COCHE',
                'SANTA ANA DE NUEVA ESPARTA',
                'VILLA ROSA'
            ]
            this.pr_estado = 'NUEVA ESPARTA'
            
        }
        if (ests == 'PORTUGUESA') {
            this.Municipios = [
                'SELECCIONAR',
                'ACARIGUA',
                'AGUA BLANCA',
                'ARAURE',
                'BISCUCUY',
                'BOCONOITO',
                'CHABASQUEN',
                'COLONIA TUNEN',
                'GUANARE',
                'GUANARITO',
                'LA MISION',
                'MESA DE CAVACAS',
                'OSPINO',
                'PAPELON',
                'PAYARA',
                'PIMPINELA',
                'PIRITU DE PORTUGUESA',
                'SAN RAFAEL DE ONOTO',
                'SANTA ROSALIA DE TUREN',
                'TUREN'                
            ]
            this.pr_estado = 'PORTUGUESA'
            
        }
        if (ests == 'SUCRE') {
            this.Municipios = [
                'SELECCIONAR',
                 'ALTOS DE SUCRE',
                 'ARAYA',
                 'CARIACO',
                 'CARUPANO',
                 'CASANAY',
                 'CUMANA',
                 'CUMANACOA',
                 'EL MORRO PUERTO SANTO',
                 'EL PILAR',
                 'GUACA',
                 'GUIRIA',
                 'IRAPA',
                 'MARIGUITAR',
                 'RIO CARIBE',
                 'RIO GRANDE',
                 'SAN ANTONIO DEL GOLFO',
                 'SAN JOSE DE AEROCUAR',
                 'SANTA FE DE SUCRE',
                 'SAN VICENTE DE SUCRE',
                 'TUNAPUY',
                 'YAGUARAPARO',
                 'YOCO',
                 
            ]
            this.pr_estado = 'SUCRE'
            
        }
        if (ests == 'TÁCHIRA') {
            this.Municipios = [
                'SELECCIONAR',
                'ABEJALES',
                'BOROTA',
                'COLON',
                'COLONCITO',
                'CORDERO',
                'EL AMPARO',
                'EL CANTON',
                'EL COBRE',
                'EL PINAL',
                'GUASDUALITO',
                'HATO DE LA VIRGEN',
                'INDEPENDENCIA',
                'LA FRIA',
                'LA FRITA',
                'LA PEDRERA',
                'LA TENDIDA',
                'LOBATERA',
                'MICHELENA',
                'PALMARITO',
                'PALMIRA',
                'PREGONERO',
                'QUENIQUEA',
                'RUBIO',
                'SAN ANTONIO DEL TACHIRA',
                'SAN CRISTOBAL',
                'SAN JOSECITO',
                'SAN JOSE DE BOLIVAR',
                'SAN JUAN DE COLON',
                'SAN PEDRO',
                'SANTA ANA TACHIRA',
                'SEBORUCO',
                'UMUQUENA',
                'UREÑA'
            ]
            this.pr_estado = 'TÁCHIRA'
            
        }
        if (ests == 'TRUJILLO') {
            this.Municipios = [
                'SELECCIONAR',
                'ALTOS DE ESTANQUE',
                'BATATAL',
                'BETIJOQUE',
                'BOCONO',
                'BOLIVIA',
                'BURBUSAY',
                'CAMPO ELIAS',
                'CARACHE',
                'CHEJENDE',
                'CUICAS',
                'EL DIVIDIVE',
                'ESCUQUE',
                'ISNOTU',
                'JAJO',
                'LA CEIBA',
                'LA CEJITA',
                'LA MESA DE ESNUJAQUE',
                'LA PUERTA',
                'MONAY',
                'MONTE CARMELO',
                'MOTATAN',
                'NIQUITAO',
                'PAMPAN',
                'PAMPANITO',
                'PUNTO PALMARITO',
                'SABANA DE MENDOZA',
                'SABANA LIBRE',
                'SAN CRISTOBAL DE TORONDOY',
                'SAN LAZARO',
                'SAN MIGUEL',
                'SANTA ANA DE TRUJILLO',
                'SANTA APOLONIA DE MERIDA',
                'SANTA APOLONIA DE TRUJILLO',
                'SANTA ISABEL',
                'TOROCOCO',
                'TOSTOS',
                'TRUJILLO',
                'VALERA'
            ]
            this.pr_estado = 'TRUJILLO'
            
        }
        if (ests == 'VARGAS') {
            this.Municipios = [
                'SELECCIONAR',
                'CARABALLEDA',
                'CARAYACA',
                'CATIA LA MAR',
                'MACUTO',
                'NAIGUATA'                
            ]
            this.pr_estado = 'VARGAS'
            
        }
        if (ests == 'YARACUY') {
            this.Municipios = [
                'SELECCIONAR',
                'AROA',
                'BORAURE',
                'CAMPO ELIAS DE YARACUY',
                'CHIVACOA',
                'COCOROTE',
                'FARRIAR',
                'GUAMA',
                'MARIN',
                'NIRGUA',
                'SABANA DE PARRA',
                'SALOM',
                'SAN FELIPE',
                'SAN PABLO DE YARACUY',
                'URACHICHE',
                'YARITAGUA',
                'YUMARE'                
            ]
            this.pr_estado = 'YARACUY'
            
        }
        if (ests == 'ZULIA') {
            this.Municipios = [
                'SELECCIONAR',
                'BACHAQUERO',
                'BOBURES',
                'CABIMAS',
                'CAMPO MARA',
                'CARRASQUERO',
                'CHIQUINQUIERA',
                'CIUDAD OJEDA',
                'EL BATEY',
                'EL CARMELO',
                'EL GUAYABO',
                'EL MENE',
                'EL VENADO',
                'ENCONTRADOS',
                'ISLA DE TOAS',
                'LAGUNILLAS DEL ZULIA',
                'LA PAZ',
                'MACHIQUES',
                'MARACAIBO',
                'MENE GRANDE',
                'PALMAREJO',
                'PUERTOS DE ALTAGRACIA',
                'PUNTA GORDA',
                'SAN JOSE DE PERIJA',
                'SAN LORENZO',
                'SAN RAFAEL DE MACHIQUES',
                'SANTA CRUZ DE MARA',
                'SANTA RITA',
                'SAN TIMOTEO',
                'SINAMAICA',
                'TIA JUANA',
                'VILLA DEL ROSARIO'
            ]
            this.pr_estado = 'ZULIA'
            
        }
        },
        loadDolarPrice(){
            fetch('https://s3.amazonaws.com/dolartoday/data.json')
            .then(response => response.json())
  .then(data => this.formats(Number.parseFloat(data.USD.dolartoday).toFixed()))
        },
        formats(input)
        {
        var num = input.replace(/\./g,'');
        if(!isNaN(num)){
        num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
        num = num.split('').reverse().join('').replace(/^[.]/,'');
        this.precioDelDolar = num
        }
        }
    },
    created(){
        this.loadDolarPrice()
    }
}
</script>